exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pools-tsx": () => import("./../../../src/pages/pools.tsx" /* webpackChunkName: "component---src-pages-pools-tsx" */),
  "component---src-pages-strategies-tsx": () => import("./../../../src/pages/strategies.tsx" /* webpackChunkName: "component---src-pages-strategies-tsx" */),
  "component---src-pages-supportirr-tsx": () => import("./../../../src/pages/supportirr.tsx" /* webpackChunkName: "component---src-pages-supportirr-tsx" */),
  "component---src-pages-treasuries-tsx": () => import("./../../../src/pages/treasuries.tsx" /* webpackChunkName: "component---src-pages-treasuries-tsx" */),
  "component---src-pages-tvls-tsx": () => import("./../../../src/pages/tvls.tsx" /* webpackChunkName: "component---src-pages-tvls-tsx" */),
  "component---src-pages-vaults-tsx": () => import("./../../../src/pages/vaults.tsx" /* webpackChunkName: "component---src-pages-vaults-tsx" */)
}

