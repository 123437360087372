export enum QueryName {
  listFarms = 'listFarms',
  listTreasuries = 'listTreasuries',
  listBanners = 'listBanners',
  listMonitorVaults = 'listMonitorVaults',
  listMonitorVaultRebalances = 'listMonitorVaultRebalances',
  listStrategies = 'listStrategies',
  listMonitorStablePools = 'listMonitorStablePools',
  listMonitorTreasuries = 'listMonitorTreasuries',
  getFarm = 'getFarm',
  getToken = 'getToken',
  userInfo = 'userInfo'
}

export type QueryNames = keyof typeof QueryName;

export type ItemsResponse<T> = {
  items: T[];
};

export type QueryItemsResponse<T> = Record<QueryNames, ItemsResponse<T>>;
export type QueryItemResponse<T> = Record<QueryNames, T>;
