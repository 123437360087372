/* eslint-disable no-param-reassign */
import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { Nullable } from 'common/models/optional';
import { APP_INSIGHTS_KEY, BUILD_TS, ENV, GIT_HASH } from 'constants/constants';
import assign from 'lodash/assign';
import { InputUtils } from 'utils/inputUtils';

export const AppInsights: Nullable<ApplicationInsights> = !InputUtils.isTruthy(APP_INSIGHTS_KEY)
  ? null
  : new ApplicationInsights({
      config: {
        instrumentationKey: APP_INSIGHTS_KEY,
        enableDebug: ENV === 'dev' || ENV === 'development',
        enableCorsCorrelation: false,
        disableCorrelationHeaders: false,
        distributedTracingMode: DistributedTracingModes.W3C
        // It's unclear how this would impact reporting, disabling
        // enableAutoRouteTracking: true
      }
    });

function initializeTelemetry(): boolean {
  if (!AppInsights) {
    return false;
  }

  AppInsights.loadAppInsights();

  const telemetryInitializer = (item: ITelemetryItem) => {
    if (item && item.tags) {
      item.tags['ai.cloud.role'] = 'stats-ichi-org-web';

      if (item.baseData) {
        // To set custom properties:
        item.baseData.properties = assign({}, item.baseData.properties, {
          'ai.cloud.role': 'stats-ichi-org-web',
          Environment: ENV,
          GIT_HASH,
          BUILD_TS
        });
      }
    }

    return true;
  };

  AppInsights.addTelemetryInitializer(telemetryInitializer);

  return true;
}

export function initialize(): boolean {
  return initializeTelemetry();
}
