import { initialize } from './src/init';

export const onClientEntry = () => {
  // TODO: See https://github.com/ethereumjs/ethereumjs-util/issues/82
  global.Buffer = global.Buffer || require('buffer/').Buffer;
  // TODO: https://www.gatsbyjs.com/docs/reference/release-notes/migrating-from-v2-to-v3/#process-is-not-defined
  // The above didn't work so have to set a global here
  global.process = global.process || require('process/browser');
  const isInitialized = initialize();
  console.debug('Initialized telemetry', isInitialized);
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(`This application has been updated. Reload to display the latest version?`);
  if (answer === true) {
    window.location.reload();
  }
};
